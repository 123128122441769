import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoCloseCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FiEdit } from "react-icons/fi";
import PropTypes from 'prop-types';

import { check } from '../../assets/svg/index.js';

import { sendOTP, signUpAPI, validateBinNumber } from "../../Services/APIs/SignUp.js";
import { resendOTP } from "../../Services/APIs/Login.js";
import { userDetail } from "../../Services/APIs/RewardListing.js";

import { useAuth } from "../../hoc/AuthContext.js";

import { createSignUpValidationSchema } from "../../Utils/Validation.js";
import { ErrorMesage, SuccessMessage } from "../../Utils/CommonFunctions.js";
import { cardTitleToPath } from "../../Utils/Constant.js";

import CreditCardInput from "../CommonComponents/CreditCardInput/index.js";
import Spinner from "../CommonComponents/Spinner/index.js";

import { POLICY_PAGE, POLICY_PRIVACY_PAGE, TERMS_PAGE } from "../../Routes/RoutesContant.js";

export default function SignUp({ setShowSignUp }) {
  const { login, setShowLogin, cardInfo, setSubVarientIdGlobal, setSelectedCardData, setWindowLocationUrl,
    windowLocationUrl, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const actualUrl = window?.location?.origin;

  const [otpValidate, setOtpValidate] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState();
  const [resendTimer, setResendTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValidateInput, setOtpValidateInput] = useState("");
  const [BinMessage, setBinMessage] = useState("")
  const [BinError, setBinError] = useState("")
  const [phoneMessage, setPhoneMessage] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [resendNumber, setResendNumber] = useState(false)
  const [cardNumber, setCardNumber] = useState("");
  const [requireEmail, setRequireEmail] = useState(false);
  const [email, setEmail] = useState("");

  const { register, handleSubmit, control, formState: { errors }, } = useForm({
    resolver: yupResolver(createSignUpValidationSchema(otpValidate)),
  });

  useEffect(() => {
    let interval;

    if (resendTimer > 0 && otpSent) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendTimer, otpSent]);

  useEffect(() => {
    if (cardNumber.length === 8 && cardNumber !== '') {
      handleBinInputChange(cardNumber);
    }
  }, [cardNumber]);

  const handleBinInputChange = async (cardNumber) => {
    if (cardNumber?.length !== 8) return setBinError("Please enter first 8 digit of your card.");

    const validateBin = async (bin) => {
      try {
        const response = await validateBinNumber(bin);
        if (response.status === 200) {
          setOtpValidateInput(cardNumber);
          setBinMessage(response?.data?.message);
        }
      } catch (e) {
        setBinMessage("");
        setBinError(e?.data?.error);
      }
    };

    if (cardNumber.length === 8) {
      await validateBin(cardNumber);
    }
  };

  const handleVerifyOTP = async (data) => {
    if (data?.mobile_number?.length < 7) return setPhoneError("Mobile Number atleast 7 digits")

    setLoading(true);

    try {
      const response = await sendOTP(data.mobile_number, countryCode, email);

      if (response.status === 200) {
        setOtpValidate(true);
        setResendNumber(true);
        setPhoneMessage(response?.data?.message);
        setPhoneError("");
        setOtpSent(true);
      }
    } catch (e) {
      setPhoneMessage("");
      setPhoneError(e ? e?.data?.error : e?.data?.mobile_number[0]);
    } finally {
      setLoading(false);
    }
  };

  // resend OTP function
  const handleResendOTP = async (e) => {
    e.preventDefault();
    setPhoneMessage("");
    setPhoneError("");
    if (phoneNumber?.length < 7) return setPhoneError("Mobile Number atleast 7 digits");
    setLoading(true);
    try {
      const response = await resendOTP(phoneNumber, countryCode, email);

      if (response.status === 200) {
        setOtpValidate(true);
        setResendNumber(true);
        setPhoneMessage(response?.data?.message);
        setPhoneError("");
        setOtpSent(true);
        setResendTimer(90);
      }
    } catch (e) {
      setPhoneError(e ? e?.data?.error : e?.data?.mobile_number[0]);
    } finally {
      setLoading(false);
    }
  }

  const handleVerifySignUp = async (data) => {
    setLoading(true);

    try {
      const response = await signUpAPI(data.name, otpValidateInput, data.mobile_number, countryCode, data.otp, email);

      if (response.status === 200) {
        setPhoneMessage(response?.data?.message);
        const token = response?.data?.token;
        login(token);

        if (windowLocationUrl) {
          await handleUserDetails();
          const url = new URL(windowLocationUrl);
          const path = url.pathname + url.search;
          if (windowLocationUrl === actualUrl) {
            await processCardInfo();
          } else {
            navigate(path);
          }
          setWindowLocationUrl("");
        } else {
          // No windowLocationUrl, check cardInfo directly
          await processCardInfo();
        }
        setIsAuthenticated(true);
        setLoading(false);
        setShowSignUp(false);
      }
    } catch (e) {
      setPhoneMessage("");
      setPhoneError(e ? e?.data?.error : e?.data?.mobile_number[0]);
    } finally {
      setLoading(false);
    }
  };

  // process the card info accordingly and redirect to the page
  const processCardInfo = async () => {
    if (cardInfo?.offer_type) {
      await handleUserDetails();
    } else if (cardInfo?.popularCategory) {
      navigate(`/rewards/?filters={"brand_categories":[${cardInfo?.id}]}`);
    } else {
      // Default case: Fetch user details and navigate
      await handleUserDetails();
      navigate(`/rewards`);
    }
  };

  // user detail for subvarient ID
  const handleUserDetails = async () => {
    try {
      const result = await userDetail();

      if (result.status === 200) {
        const defaultCard = result.data.cards.find((item) => item.is_default);

        if (defaultCard) {
          localStorage.setItem('subId', defaultCard.sub_variant.id);
          setSubVarientIdGlobal(defaultCard.sub_variant.id);
          localStorage.setItem('selectedCard', JSON.stringify(defaultCard));
          setSelectedCardData(defaultCard);

          const path = cardTitleToPath(cardInfo?.offer_type);
          navigate(`/rewards/${path}/${cardInfo?.id}/${cardInfo?.slug}`);
        } else {
          navigate("/rewards");
        }
      } else {
        navigate("/rewards");
      }
    } catch (error) {
      navigate("/rewards");
    } finally {
      setLoading(false);
      setShowLogin(false);
    }
  };

  const onSubmit = async (data) => {
    if (!otpValidate || !resendNumber) {
      handleVerifyOTP(data);
    } else {
      handleVerifySignUp(data);
    }
  };

  const handleChangeNumber = () => {
    setResendNumber(false);
    setOtpValidate(false);
    setPhoneMessage("");
    setPhoneError("");
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  function renderPhoneInput() {
    return (
      <div className="w-full pt-4 md:pt-6">
        <Controller
          name="mobile_number"
          control={control}
          render={({ field }) => (
            <div className="relative z-0">
              <PhoneInput
                country={"in"}
                countryCodeEditable={false}
                onChange={(value, country) => {
                  const phoneWithoutCountryCode = value.replace(country.dialCode, "").trim();
                  field.onChange(phoneWithoutCountryCode);
                  setCountryCode(country.dialCode);
                  setPhoneNumber(phoneWithoutCountryCode);
                  // Check if the selected country is not India
                  if (country.dialCode !== "91") {
                    setRequireEmail(true);
                  } else {
                    setRequireEmail(false);
                    setEmail("");
                  }
                }}
                placeholder="Mobile Number"
                inputMode="numeric"
                inputClass="font-Inter"
                disableDropdown={otpValidate}
                disabled={resendNumber}
              />

              {resendNumber && (
                <div
                  className="z-10 font-medium text-sm underline py-3 absolute top-2 right-4 font-Inter-Tight cursor-pointer"
                  onClick={handleChangeNumber}
                >
                  <FiEdit size={18} />
                </div>
              )}
            </div>
          )}
        />

        {phoneMessage && (
          <div className="flex mt-2 gap-2 text-sm text-[#09CB11] font-Inter font-medium">
            <img src={check} alt="check" />
            {phoneMessage}
          </div>
        )}

        {phoneError && !phoneMessage && (
          <div className="flex items-center mt-2 gap-2 text-sm text-red-600 font-Inter font-medium">
            <IoCloseCircle color="#e52424" size={18} />
            {phoneError}
          </div>
        )}
      </div>
    );
  };

  function renderEmailInput() {
    return (
      <div className="w-full pt-6">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              value={email} // Bind email state to the input
              onChange={(e) => setEmail(e.target.value)} // Update email state on input change
              className="w-full rounded-[10px] font-Inter h-[60px] border border-[#E5E5E5] focus:outline-none py-3 px-6 bg-[#F7F7F7] text-[#1e1e1e] text-lg font-medium placeholder-shown:font-normal placeholder-[#6A6A6A]"
              type="email"
              placeholder="Email Address"
              disabled={otpValidate}
            />
          )}
        />

        {errors?.email && (
          <p className="py-2 px-4 text-red-600 font-Inter text-xs">
            {errors?.email?.message}
          </p>
        )}
      </div>
    );
  };

  function renderOtpInput() {
    return (
      <div className="w-full pt-6 relative">
        <input
          className="w-full otpInput rounded-[10px] font-Inter h-[60px] border border-[#E5E5E5] focus:outline-none py-3 px-6 bg-[#F7F7F7] text-[#1e1e1e] text-lg font-medium placeholder-shown:font-normal placeholder-[#6A6A6A]"
          id="otp"
          type="text"
          placeholder="Enter OTP"
          pattern="[0-9]{6}"
          minLength={6}
          maxLength={6}
          inputMode="numeric"
          {...register("otp")}
        />

        <span className="redStar text-[#FF0055]" style={{ display: otp ? 'none' : 'block' }}>*</span>

        {errors.otp && (
          <p className="py-2 px-4 text-red-600 font-Inter text-xs">
            {errors.otp.message}
          </p>
        )}

        {otpSent && (
          <div className="py-3 absolute top-[30px] right-4 font-Inter-Tight">
            {resendTimer > 0 ? (
              <button className="font-medium text-sm" disabled>
                Resend OTP in: {Math.floor(resendTimer / 60)}:
                {("0" + (resendTimer % 60)).slice(-2)}
              </button>
            ) : (
              <button className="font-medium text-sm underline" onClick={handleResendOTP}>
                Resend OTP
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full mx-auto h-full flex justify-center items-center flex-col">
      <div className="py-6 container md:w-full border border-[#BDBDBD] bg-white rounded-[20px] flex flex-col gap-4 mx-auto justify-center px-6">
        <div className="text-2xl font-semibold font-Inter">
          Sign up for free
        </div>

        <div className="text-lg font-normal font-Inter">
          Please enter the following details
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)} onKeyDown={handleEnterKey}
          onChange={e => { if (e.target.id === 'otp') setOtp(e.target.value) }}
        >
          <div className="w-full pb-4 md:pb-6">
            <input
              className="w-full appearance-none font-Inter rounded-[10px] h-[60px] border border-[#E5E5E5] focus:outline-none py-3 px-6 bg-[#F7F7F7] text-[#1e1e1e] text-lg font-medium placeholder-shown:font-normal placeholder-[#20244E]"
              id="name"
              placeholder="Full Name"
              type="text"
              {...register("name")}
              disabled={otpValidate}
            />

            {errors.name && (
              <p className="py-2 px-4 text-red-600 font-Inter text-xs">
                {errors.name.message}
              </p>
            )}
          </div>

          <div className="font-normal font-Inter mb-3">
            Please enter your 8 digit card number
          </div>

          <CreditCardInput setCardNumber={setCardNumber} />
          <SuccessMessage message={BinMessage} />
          <ErrorMesage error={BinError} message={BinMessage} />

          {renderPhoneInput()}

          {/* Conditionally Render Email Input */}
          {requireEmail && renderEmailInput()}

          {otpValidate && renderOtpInput()}

          <div className="py-5 text-sm font-Inter-Tight">
            By continuing I agree to the{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setShowSignUp(false);
                navigate(`${POLICY_PAGE}/${TERMS_PAGE}`)
              }}
            >terms & conditions</span>{" "}
            and{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setShowSignUp(false);
                navigate(`${POLICY_PAGE}/${POLICY_PRIVACY_PAGE}`)
              }}
            >privacy policy</span>
          </div>

          <button
            className={`w-full font-Inter h-15 border py-4 border-[#E11F60] bg-[#F5437E] rounded-[10px] text-xl font-semibold text-white ${loading && "bg-[#f78baf] border-[#f78baf]"}`}
            disabled={loading}
          >
            {loading ? <Spinner color="#fff" /> : "Validate"}
          </button>
        </form>

        <div className="text-center font-Inter font-semibold text-lg">
          Already a Banco user?{" "}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              setShowSignUp(false);
              setShowLogin(true);
            }}
          >
            Sign in
          </span>
        </div>
      </div >
    </div >
  );
};

SignUp.propTypes = {
  setShowSignUp: PropTypes.func.isRequired,
};
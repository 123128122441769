export const HOMEPAGE = "";

export const REWARD_LISTING = "rewards";

export const REWARD_DETAIL_GIFTCARD_PAGE = "rewards/giftcard/:id/:slugId";
export const REWARD_DETAIL_DISCOUNTOFFER_PAGE = "rewards/discount/:id/:slugId";
export const REWARD_DETAIL_EXPERIENCES_PAGE = "rewards/experience/:id/:slugId";
export const REWARD_DETAIL_MEMBERSHIP_PAGE = "rewards/membership/:id/:slugId";
export const REWARD_DETAIL_CASHBACK_PAGE = "rewards/cashback/:id/:slugId";

export const PUBIC_REWARD_DETAIL_PAGE = "rewards/:brandName/:integratorId/:publicsubvarientId/:id";

export const REWARD_SUMMARY_PAGE = "order/:offerType/:id";

export const USER_PROFILE_PAGE = "userProfile";

export const ASSIGNED_OFFER_PAGE = "assignedOffer";
export const USER_ORDER_PAGE = "order";
export const USER_REWARD_PAGE = "reward";
export const USER_WISHLIST_PAGE = "wishlist";
export const USER_NOTIFICATON_PAGE = "notification";

export const CONTACT_US_PAGE = "contact-us";

export const POLICY_PAGE = "policy";

export const POLICY_PRIVACY_PAGE = "privacyPolicy";

export const TERMS_PAGE = "termsOfUse";

export const FAQ_PAGE = "faq";

export const DISCLAIMER_PAGE = "disclaimer";

export const REFUND_PAGE = "refundPolicy";
import React from 'react';
import { Outlet } from 'react-router-dom';

import { getSubdomainInfo } from './Utils/CommonFunctions';

import Header from "./components/CommonComponents/Header";
import Footer from "./components/CommonComponents/Footer";

export default function App() {
  // Subdomain and theme information
  const { isSubdomain, subdomainName } = getSubdomainInfo();
  const isMainDomain = !isSubdomain;

  // Determine theme based on subdomain
  let theme = 'main'; // Default
  if (subdomainName === 'rewards') theme = 'rewards';
  if (subdomainName === 'kotak') theme = 'kotak';
  if (subdomainName === 'visa') theme = 'visa';
  // if (isSubdomain && subdomainName !== 'rewards') theme = 'sib';

  // const ThemeProvider = ({ theme, children }) => {
  //   return (
  //     <div className={`theme-${theme}`}>
  //       {children}
  //     </div>
  //   );
  // };

  return (
    <div className={`theme-${theme}`}>
      <Header isMainDomain={isMainDomain} theme={theme} />
      <Outlet />
      <Footer theme={theme} />
    </div>
  );
};
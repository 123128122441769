export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL;

export const ENDPOINTS = {
	// AUTHENTICATION
	OTP_VALIDATION: "/user/public/send-otp/",
	LOGIN: "/user/public/signin/",
	SIGNUP: "/user/public/signup/",
	RESEND_OTP: "/user/public/resend-otp/",

	// VALIDATE BIN NUMBER
	VALIDATION_BIN: (id) => `/bank/public/validate-bin/${id}`,

	// USER DETAIL
	FULL_USER_DETAIL: "/user/?details=full",
	USER_DETAIL: "/user/",

	// GLOBAL SEARCH
	GLOBAL_SEARCH: (id) => `/base/public/global-search/?${id}`,

	// REWARD LISTING 
	ADD_CARD: "/bank/card/",
	OFFER_CATEGORY: "/base/offer-categories/",
	EVENT_LIST: "/base/events/",
	BRAND_LIST: (id) => `/brand/public/list/?${id}`,
	BRAND_CATEGORY_LIST: "/base/brand-categories/",
	OFFER_TYPES_LIST: "/base/public/offer-types/",
	FILTER_SEARCH_RESULT: (id) => `/base/get-all-rewards/?${id}`,

	//LANDING PAGE
	BANNER_URL: "/base/public/banners/?page=landing",
	WHATSNEW_URL: (id) => `/base/public/new-rewards/?${id}`,
	POPULAR_CATEGORIES_URL: (id) => `/base/public/popular-categories/?${id}`,
	PUBLIC_REWARDS_URL: (id) => `/base/public/get-rewards?offer_type=${id}`,
	FOOTER_STAT_URL: '/base/public/stats/',

	// REWARD DETAIL PAGE
	REWARD_DETAIL: (id) => `/base/get-reward-details/?${id}`,
	SIMILAR_RECOMMENDED_OFFER: (id) => `/base/get-similar-rewards/?${id}`,
	BANNER_DATA: (id) => `/base/banners/?${id}`,
	GET_CODE: (id) => `/brand/user/get-code/?${id}`,
	GET_LINK: (id) => `/affiliate/user/get-link/?${id}`,
	GET_USER_LOCATION: (id) => `/brand/public/nearby-store/?${id}`,
	ADD_ASSIGN_REWARD: "/membership/user/assign/",

	// PUBLIC REWARD DETAIL PAGE
	PUBIC_REWARD_DETAIL: (id) => `/base/public/reward-detail/?${id}`,
	PUBLIC_CODE: '/brand/public/get-offer/',
	INTEGRATOR_DETAIL: `integrator/public/`,

	// USER PROFILE PAGE
	PATCH_USER_UPDATE: "/user/update/",
	DELETE_CARD: (id) => `/bank/card/?${id}`,
	MAKE_DEFAULT_CARD: `/bank/card/make_default/`,
	SAVED_OFFER: (id) => `/base/user/saved-rewards?${id}`,
	ASSIGNED_OFFER: (id) => `/base/user/assigned-rewards?${id}`,

	// CONTACT US PAGE
	CREATE_CONTACT_US: "/base/public/form/",

	// PAYMENT INTEGRATION GIFTCARD
	PURCHASE_GIFTCARD: "/gift-card/user/purchase-gift-card/",

	// GIFTCARD ORDER SUMMARY
	GIFTCARD_ORDER_SUMMARY: (id) => `/gift-card/user/order-details/?${id}`,

	// ORDER HISTORY
	ORDER_HISTORY: (id) => `/base/user/orders?${id}`,

	// LOYALITY POINTS HISTORY
	LOYALITY_POINTS_HISTORY: `/loyalty/history`,
}

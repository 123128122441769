import { lazy, Suspense, useEffect } from "react";
import { createBrowserRouter } from "react-router-dom";

import App from "../App.js";

import Error from "../components/CommonComponents/Error/index.js";
import ContactUsPageSkeleton from "../components/CommonComponents/SkeletonUI/ContactUsSkeleton.js";
import LandingPageSkeleton from "../components/CommonComponents/SkeletonUI/LandingPageSkeleton.js";
import Spinner from "../components/CommonComponents/Spinner/index.js";

import PrivateRoute from "./PrivateRoute.js";

import { getSubdomainInfo } from "../Utils/CommonFunctions.js";

import ErrorBoundary from "../hoc/ErrorBoundary.js";

import {
  ASSIGNED_OFFER_PAGE, CONTACT_US_PAGE, DISCLAIMER_PAGE, FAQ_PAGE, HOMEPAGE, POLICY_PAGE, POLICY_PRIVACY_PAGE, PUBIC_REWARD_DETAIL_PAGE, REFUND_PAGE, REWARD_DETAIL_CASHBACK_PAGE, REWARD_DETAIL_DISCOUNTOFFER_PAGE, REWARD_DETAIL_GIFTCARD_PAGE, REWARD_DETAIL_MEMBERSHIP_PAGE, REWARD_LISTING, REWARD_SUMMARY_PAGE, TERMS_PAGE, USER_NOTIFICATON_PAGE, USER_ORDER_PAGE, USER_PROFILE_PAGE, USER_REWARD_PAGE, USER_WISHLIST_PAGE,
} from "./RoutesContant.js";

import OfferSummaryPageSkeleton from "../components/CommonComponents/SkeletonUI/OrderSummarypageSkeleton.js";
import RewardDetailSkeleton from "../components/CommonComponents/SkeletonUI/RewardDetailSkeleton.js";
import RewardListingSkeleton from "../components/CommonComponents/SkeletonUI/RewardListingSkeleton.js";
import { useAuth } from "../hoc/AuthContext.js";

// Lazy load the components
const RewardListing = lazy(() => import("../components/RewardListing"));
const LandingPage = lazy(() => import("../components/LandingPage"));
const HomePage = lazy(() => import("../components/Homepage"));
const RewardDetailGiftCardPage = lazy(() => import("../components/RewardDetailPage/GiftCardRewardDetailPage.js"));
const RewardDetailCashbackPage = lazy(() => import("../components/RewardDetailPage/CashbackRewardDetailPage.js"));
const RewardDetailMembershipPage = lazy(() => import("../components/RewardDetailPage/AccessMembershipRewardDetailPage.js"));
// const RewardDetailExperiencePage = lazy(() => import("../components/RewardDetailPage/ExperienceRewardDetailPage.js"));
const RewardDetailDiscountPage = lazy(() => import("../components/RewardDetailPage/DiscountRewardDetailPage.js"));
const OfferSummaryPage = lazy(() => import("../components/OfferSummaryPage"));
const UserProfliePage = lazy(() => import("../components/UserProfile"));
const AssignedOffer = lazy(() => import("../components/UserProfile/AssignedOffer.js"));
const OrderPage = lazy(() => import("../components/UserProfile/OrderHistory.js"));
const RewardPage = lazy(() => import("../components/UserProfile/Rewardtab.js"));
const WishlistPage = lazy(() => import("../components/UserProfile/WishlistTab.js"));
const NotificationPage = lazy(() => import("../components/UserProfile/NotificationSetting.js"));
const ProfilePage = lazy(() => import("../components/UserProfile/Profiletab.js"));
const ContactUsPage = lazy(() => import("../components/ContactUsPage"));
const PolicyPage = lazy(() => import("../components/PrivacyPolicyPage"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicyPage/PrivacyPolicy.js"));
const Faq = lazy(() => import("../components/PrivacyPolicyPage/Faq.js"));
const Disclaimer = lazy(() => import("../components/PrivacyPolicyPage/Disclaimer.js"));
const Refund = lazy(() => import("../components/PrivacyPolicyPage/Refund.js"));
const TermsOfUse = lazy(() => import("../components/PrivacyPolicyPage/TermOfUse.js"));

// Subdomain info
const { isSubdomain, subdomainName } = getSubdomainInfo();
const isMainDomain = !isSubdomain;
const windowURLSearchParam = window?.location?.search;
const windowLocationUrl = window?.location?.href;

const AppWithState = () => {
  const { setWindowLocationUrl } = useAuth();
  useEffect(() => {
    // Set windowLocationUrl when the component mounts
    setWindowLocationUrl(windowLocationUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <App />
  );
};

// Error boundary wrapper component
const WrapWithErrorBoundary = ({ children, skeleton }) => (
  <ErrorBoundary fallback={<Error />}>
    <Suspense fallback={skeleton || <Spinner />}>{children}</Suspense>
  </ErrorBoundary>
);

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <AppWithState />,
    errorElement: <Error />,
    children: [
      {
        path: HOMEPAGE,
        element:
          <WrapWithErrorBoundary skeleton={<LandingPageSkeleton />}>
            {isMainDomain ? <HomePage /> : <LandingPage />}
          </WrapWithErrorBoundary>,
        errorElement: <Error />,
      },
      {
        path: CONTACT_US_PAGE,
        element: <WrapWithErrorBoundary skeleton={<ContactUsPageSkeleton />}><ContactUsPage /></WrapWithErrorBoundary>,
        errorElement: <Error />,
      },
      {
        path: PUBIC_REWARD_DETAIL_PAGE,
        element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailDiscountPage subdomainName={subdomainName} isSubdomain={isSubdomain} isPublic={true} /></WrapWithErrorBoundary>,
        errorElement: <Error />,
      },
      {
        path: POLICY_PAGE,
        element: <WrapWithErrorBoundary><PolicyPage /></WrapWithErrorBoundary>,
        errorElement: <Error />,
        children: [
          {
            path: POLICY_PRIVACY_PAGE,
            element: <WrapWithErrorBoundary><PrivacyPolicy /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: TERMS_PAGE,
            element: <WrapWithErrorBoundary><TermsOfUse /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: FAQ_PAGE,
            element: <WrapWithErrorBoundary><Faq /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: DISCLAIMER_PAGE,
            element: <WrapWithErrorBoundary><Disclaimer /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: REFUND_PAGE,
            element: <WrapWithErrorBoundary><Refund /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
        ],
      },
      {
        path: "/",
        element: <PrivateRoute />,
        errorElement: <Error />,
        children: [
          // {
          //   path: PUBIC_REWARD_DETAIL_PAGE,
          //   element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailDiscountPage /></WrapWithErrorBoundary>,
          //   errorElement: <Error />,
          // },
          {
            path: REWARD_LISTING,
            element: (
              <WrapWithErrorBoundary skeleton={<RewardListingSkeleton />}>
                <RewardListing windowURLSearchParam={windowURLSearchParam} />
              </WrapWithErrorBoundary>
            ),
            errorElement: <Error />,
          },
          {
            path: REWARD_DETAIL_GIFTCARD_PAGE,
            element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailGiftCardPage /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: REWARD_DETAIL_DISCOUNTOFFER_PAGE,
            element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailDiscountPage isPublic={false} /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          // {
          //   path: REWARD_DETAIL_EXPERIENCES_PAGE,
          //   element: <WrapWithErrorBoundary><RewardDetailExperiencePage /></WrapWithErrorBoundary>,
          //   errorElement: <Error />,
          // },
          {
            path: REWARD_DETAIL_MEMBERSHIP_PAGE,
            element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailMembershipPage /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: REWARD_DETAIL_CASHBACK_PAGE,
            element: <WrapWithErrorBoundary skeleton={<RewardDetailSkeleton />}><RewardDetailCashbackPage /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: REWARD_SUMMARY_PAGE,
            element: <WrapWithErrorBoundary skeleton={<OfferSummaryPageSkeleton />}><OfferSummaryPage /></WrapWithErrorBoundary>,
            errorElement: <Error />,
          },
          {
            path: USER_PROFILE_PAGE,
            element: <WrapWithErrorBoundary><UserProfliePage /></WrapWithErrorBoundary>,
            errorElement: <Error />,
            children: [
              {
                path: "",
                element: <WrapWithErrorBoundary><ProfilePage /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
              {
                path: USER_ORDER_PAGE,
                element: <WrapWithErrorBoundary><OrderPage /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
              {
                path: USER_WISHLIST_PAGE,
                element: <WrapWithErrorBoundary><WishlistPage /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
              {
                path: USER_REWARD_PAGE,
                element: <WrapWithErrorBoundary><RewardPage /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
              {
                path: USER_NOTIFICATON_PAGE,
                element: <WrapWithErrorBoundary><NotificationPage /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
              {
                path: ASSIGNED_OFFER_PAGE,
                element: <WrapWithErrorBoundary><AssignedOffer /></WrapWithErrorBoundary>,
                errorElement: <Error />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
